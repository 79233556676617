import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Input, notification, Pagination, Select } from "antd"; // Added notification import
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import "./style.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  getAllCategory,
  addCategory,
  deleteCategory,
  updateCategory,
} from "../../services/category";
import { notifyWarn } from "../../components/Toast";
import formatDate from "../../services/formatDate";
import LineChartComponent from "../../components/LineChartComponent";
const { Column } = Table;
const { Search } = Input;

const UserTable = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState({});
  const [data, setData] = useState();
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [CategoryData, setCategoryData] = useState([]);

  const [categoryVideoCount, setCategoryVideoCount] = useState([]);

  const [editedCategory, setEditedCategory] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const [showmessage, setshowmessage] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default to 10 rows per page


  const [newCategory, setNewCategory] = useState({
    name: "",
    description: "",
  });

  const [filterObject, setfilterObject] = useState({
    searchString: "",
  });

  const onChangePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const fetchCategoryData = (pgnmbr, obj) => {
    setIsLoading(true);

    getAllCategory(pgnmbr, obj)
      .then((resp) => {
        // Set the data state with the API response
        setData(resp?.data?.categories);
        setTotalUsers(resp?.data?.totalCount);
        setCategoryVideoCount(resp?.data?.categoryVideoCount);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("API error:", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchCategoryData(pageNumber, filterObject);
  }, [pageNumber, rowsPerPage, reload]);

  const handleAddClick = () => {
    setNewCategory({
      // Clear the form fields
      name: "",
      description: "",
    });
    setAddModalVisible(true);
  };

  const handleAddSubmit = () => {
    if (newCategory.name.length <= 1) {
      setshowmessage(true);
    } else {
      addCategory(newCategory)
        .then((resp) => {
          console.log("Category added:", resp);
          // Close the add modal or update the data as needed
          setAddModalVisible(false);
          // If you want to update the table data, you can fetch the data again.
          fetchCategoryData(pageNumber, filterObject);
          openNotificationWithIcon(
            "success",
            "Category Added",
            "Category has been successfully added."
          );
        })
        .catch((err) => {
          console.error("Add category error:", err);
          openNotificationWithIcon(
            "error",
            "Add Category Error",
            "There was an error adding the category."
          );
          // Handle the error as needed (e.g., show an error message).
        });
    }
  };

  const handleEditClick = (record) => {
    setEditingRecord(record);
    setEditedCategory({
      name: record.name,
      description: record.description,
    });
    setEditModalVisible(true);
  };

  const showDeleteConfirmation = (record) => {
    setIsDeleteModalOpen(true);
    setDeleteId(record._id);
  };

  const handleDeleteClick = () => {
    deleteCategory(deleteId)
      .then((resp) => {
        const updatedData = data.filter((item) => item._id !== deleteId);
        setData(updatedData);
        openNotificationWithIcon(
          "success",
          "Category Deleted",
          "Category has been successfully deleted."
        );
        setModalVisible(false); // Close the modal if it's open
        setIsDeleteModalOpen(false); // Close the delete confirmation modal
        setDeleteId(null);
      })
      .catch((err) => {
        console.error("Delete category error:", err);
        openNotificationWithIcon(
          "error",
          "Delete Category Error",
          "There was an error deleting the category."
        );
        setIsDeleteModalOpen(false); // Close the delete confirmation modal
        setDeleteId(null);
        // Handle the error as needed (e.g., show an error message).
      });
  };

  const handleRowClick = (record) => {
    console.log("Row clicked:", record);
    setSelectedCategory(record);
    setModalVisible(true);
  };

  const handleEditSubmit = () => {
    updateCategory(editingRecord._id, editedCategory)
      .then((resp) => {
        console.log("Category updated:", resp);
        setEditModalVisible(false); // Close the edit modal
        // Fetch the updated data to refresh the table
        fetchCategoryData(pageNumber, filterObject);
        openNotificationWithIcon(
          "success",
          "Category Updated",
          "Category has been successfully updated."
        );
      })
      .catch((err) => {
        console.error("Update category error:", err);
        openNotificationWithIcon(
          "error",
          "Update Category Error",
          "There was an error updating the category."
        );
        // Handle the error as needed (e.g., show an error message).
      });
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };
  const scrollConfig = {
    y: "55vh", // Set the maximum height in pixels or other CSS units
  };

  const handleSearch = (value, _e, info) => {
    if (!value) {
      notifyWarn("Enter v value first");
      return;
    }
    setfilterObject({ searchString: value });
    setReload(!reload);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Number of Videos",
      dataIndex: "videoCount",
      key: "videoCount",
    },
  ];

  const createdAtSorter = (a, b) =>
    new Date(a.createdAt) - new Date(b.createdAt);

  return (
    <div className="userContainer">
      <div className="tableInfo">
        <span style={{ color: "white" }} className="spanstyle">
          CATEGORIES
        </span>
        <Button
          style={{
            fontSize: 12,
            background: "rgba(39, 253, 150, 1)",
            color: "black",
            fontWeight: "bold",
          }}
          type="primary"
          onClick={handleAddClick}
        >
          Add New Category
        </Button>

        <Search
          placeholder="input search text"
          allowClear
          enterButton="Search"
          size="small"
          style={{
            width: 304,
          }}
          onSearch={handleSearch}
          onChange={(e) => {
            if (!e.target.value) {
              setfilterObject({ searchString: "" });
              setReload(!reload);
            }
          }}
        />
      </div>

      <div className="responsivetable">
        <div className="tableDatac" style={{ border: "none !important", borderRadius: '0px !important' }}>
          <Table
            dataSource={data}
            loading={isLoading ? true : false}
            className="custom-table"
            scroll={{ x: '100%' }}
            pagination={{ pageSize: rowsPerPage }}
          >
            <Column
              title="Name"
              dataIndex="name"
              key="name"
              width="20%"
              sorter={(a, b) => a.name.localeCompare(b.name)}
            />

            <Column
              title="Description"
              dataIndex="description"
              key="description"
              width="20%"
              sorter={(a, b) => a.description.localeCompare(b.description)}
            />

            <Column
              width="50%"
              title="Created Date"
              key="createdAt"
              dataIndex="createdAt"
              render={(text, record) => (
                <span>{formatDate(record?.createdAt)}</span>
              )}
              sorter={createdAtSorter}
            />
            <Column
              width="10%"
              title="Action"
              key="action"
              render={(text, record) => (
                <>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(record)}
                  >
                    <EyeOutlined />
                  </span>
                  <span
                    style={{ marginLeft: 16, cursor: "pointer" }}
                    onClick={() => handleEditClick(record)}
                  >
                    <EditOutlined />
                  </span>
                  <span
                    style={{ marginLeft: 16, cursor: "pointer" }}
                    onClick={() => showDeleteConfirmation(record)}
                  >
                    <DeleteOutlined />
                  </span>
                </>
              )}
            />
          </Table>
          <div className="paginationComp">
            <Pagination
              defaultCurrent={1}
              total={totalUsers}
              onChange={onChangePagination}
              pageSize={rowsPerPage}
            />

            <Select
              placeholder="Rows per page"
              value={rowsPerPage}
              style={{ width: 150, marginLeft: '16px' }} // Margin for spacing
              onChange={(value) => {
                console.log("value>>>>>>>>>>>>", value);
                setRowsPerPage(value);
              }}
              options={[
                { label: "5 / page", value: 5 },
                { label: "10 / page", value: 10 },
                { label: "20 / page", value: 20 },
                { label: "50 / page", value: 50 },
              ]}
            />
          </div>
        </div>
        <div className="test">
          <LineChartComponent
            data={categoryVideoCount}
            title={"Category vs Videos"}
          />
        </div>
      </div>



      <Modal
        title="Category Details!"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={() => setModalVisible(false)}
          >
            OK
          </Button>,
        ]}
      >
        <p>
          <strong>Name:</strong> {selectedCategory.name}
        </p>
        <p>
          <strong>Description:</strong> {selectedCategory.description}
        </p>
        <p>
          <strong>CreatedAt:</strong> {selectedCategory.createdAt}
        </p>
        <p>
          <strong>UpdatedAt:</strong> {selectedCategory.updatedAt}
        </p>
      </Modal>

      <Modal
        title="Edit Category"
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setEditModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleEditSubmit}>
            Update
          </Button>,
        ]}
      >
        <div style={{ marginBottom: 10 }}>
          <span htmlFor="name">Name:</span>
          <Input
            id="name"
            value={editedCategory.name}
            onChange={(e) =>
              setEditedCategory({ ...editedCategory, name: e.target.value })
            }
            style={{ marginTop: 5 }}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <label htmlFor="description">Description:</label>
          <Input
            id="description"
            name="description" // Add this line
            value={editedCategory.description}
            onChange={(e) =>
              setEditedCategory({
                ...editedCategory,
                description: e.target.value,
              })
            }
            style={{ marginTop: 5 }}
          />
        </div>
      </Modal>

      <Modal
        title="Delete Alert!"
        visible={isDeleteModalOpen}
        onOk={handleDeleteClick}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setDeleteId(null);
        }}
      >
        <p>Are you sure you want to delete this category?</p>
      </Modal>

      <Modal
        title="Add Category"
        visible={addModalVisible}
        onCancel={() => setAddModalVisible(false)}
        onOk={handleAddSubmit}
      >
        <div style={{ marginBottom: 10 }}>
          <span htmlFor="name">Name:</span>

          <Input
            id="name"
            value={newCategory.name}
            onChange={(e) => {
              setNewCategory({ ...newCategory, name: e.target.value });
              setshowmessage(false);
            }}
            style={{ marginTop: 5 }}
          />

          {showmessage && (
            <span style={{ color: "red", fontSize: "10px" }}>
              Category must have a name
            </span>
          )}
        </div>
        <div style={{ marginBottom: 10 }}>
          <label htmlFor="description">Description:</label>
          <Input
            id="description"
            value={newCategory.description}
            onChange={(e) =>
              setNewCategory({ ...newCategory, description: e.target.value })
            }
            style={{ marginTop: 5 }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default UserTable;
