import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  message,
  Avatar,
  Pagination,
  Select,
  Button,
  Input,
} from "antd";
import { Switch } from "antd";
import { notifyError, notifySuccess, notifyWarn } from "../../components/Toast";
import "./style.scss";
import {
  PlayCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  PlaySquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import swal from "sweetalert";

import ReactPlayer from "react-player";
import { getFeedbacks } from "../../services/category";
import { ThumbnailPreviewer } from "../../components/ThumbnailPreviewer";
import formatDate from "../../services/formatDate";

const { Search } = Input;
const { Column } = Table;

const Feedbacks = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [videoDetail, setVideoDetail] = useState({});
  const [videos, setVideos] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default to 10 rows per page
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [IsPrivate, setIsPrivate] = useState(null);

  const [searchedText, setSearchedText] = useState("");

  const [filterObject, setfilterObject] = useState({
    isFeatured: "",
    isPrivate: "",
    name: "",
  });

  const [reload, setReload] = useState(false);

  useEffect(() => {
    fetchVideosdData(pageNumber, filterObject);
  }, [pageNumber, reload, rowsPerPage]);

  const onChangePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const createdAtSorter = (a, b) =>
    new Date(a.createdAt) - new Date(b.createdAt);

  const fetchVideosdData = (pgnumber) => {
    setIsLoading(true);

    getFeedbacks(pgnumber)
      .then((result) => {
        if (result.type === "success") {
          setVideos(result?.data);
          setIsLoading(false);
        } else {
          setVideos([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error("Failed to fetch videos");
        console.error(error);
        setIsLoading(false);
        setVideos([]);
      });
  };

  const columns = [
    {
      title: "User Picture",
      dataIndex: "user",
      key: "user",
      render: (text, record) => (
        <Avatar
          style={{ cursor: "pointer" }}
          onClick={() => setPreviewUrl(record?.user?.profilePic)}
          size={60}
          src={record?.user?.profilePic ? record?.user?.profilePic : null}
          icon={!record?.user?.profilePic && <PlaySquareOutlined />}
        />
      ),
    },

    {
      title: "User Name",
      dataIndex: "user",
      key: "user",
      render: (text, record) => <span>{record.user.name}</span>,
      sorter: (a, b) => a.user.name.localeCompare(b.user.name),
    },

    {
      title: "Rating",
      dataIndex: "ratingPoints",
      key: "ratingPoints",
      sorter: (a, b) => a.ratingPoints - b.ratingPoints,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      sorter: (a, b) => a.message.localeCompare(b.message),
    },
    {
      title: "Created Date",
      key: "createdAt",
      dataIndex: "createdAt",

      render: (text, record) => <span>{formatDate(record?.createdAt)}</span>,

      sorter: { createdAtSorter },
    },
  ];

  const scrollConfig = {
    y: "60vh", // Set the maximum height in pixels or other CSS units
  };

  const handleApplyFilter = () => {
    if (!selectedFeature && !IsPrivate) {
      notifyWarn("select a value first");
      return;
    }
    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      isPrivate: IsPrivate,
    }));

    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      isFeatured: selectedFeature,
    }));

    setReload(!reload);
  };

  const handleSearch = (value, _e, info) => {
    if (!value) {
      notifyWarn("Enter v value first");
      return;
    }

    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      name: value,
    }));

    setReload(!reload);
  };

  return (
    <div className="userContainer">
      <div className="tableInfo">
        <span style={{ color: "white" }} className="spanstyle">
          Feed Backs
        </span>
        {previewUrl && (
          <ThumbnailPreviewer
            previewUrl={previewUrl}
            setPreviewUrl={setPreviewUrl}
            handleDeleteImage={() => { }}
          />
        )}
      </div>

      <div className="responsivetable">
        <div className="tableData">
          {videos && (
            <Table
              dataSource={videos}
              columns={columns}
              loading={isLoading ? true : false}
              scroll={{ x: "100%" }}
              pagination={{ pageSize: rowsPerPage }}

            />
          )}
        </div>

        <div className="paginationComp">
          {/* Pagination Component */}
          <Pagination
            defaultCurrent={1}
            total={totalUsers}
            onChange={onChangePagination}
            pageSize={rowsPerPage}
          />

          {/* Rows per page Select */}
          <Select
            placeholder="Rows per page"
            value={rowsPerPage}
            style={{ width: 150, marginLeft: '16px' }} // Margin for spacing
            onChange={(value) => {
              setRowsPerPage(value);
            }}
            options={[
              { label: "5 / page", value: 5 },
              { label: "10 / page", value: 10 },
              { label: "20 / page", value: 20 },
              { label: "50 / page", value: 50 },
            ]}
          />
          {/* Your button in the bottom right corner */}
        </div>
      </div>

      <Modal
        title="Delete Alert!"
        open={isDeleteModalOpen}
        onOk={() => { }}
        okText="Confirm"
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setdeleteId(null);
        }}
      >
        <p>Are you sure to Delete...</p>
      </Modal>

      <Modal
        title="Video Details!"
        open={isDetailsModalOpen}
        onOk={() => {
          setIsDetailsModalOpen(false);
          setVideoDetail({});
        }}
        onCancel={() => {
          setIsDetailsModalOpen(false);
          setVideoDetail({});
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span className="title" style={{ fontWeight: "bold" }}>
              video Thumbnail:
            </span>
            {/* <img
              src={videoDetail?.thumbnailUrl}
              alt="thumbnail"
              style={{
                width: "120px",
                maxHeight: "30px",
              }}
            /> */}

            <Avatar
              size={200}
              src={videoDetail?.thumbnailUrl ? videoDetail?.thumbnailUrl : null}
              icon={!videoDetail?.thumbnailUrl && <PlaySquareOutlined />}
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Name :
            </span>
            <span className="detail">{videoDetail?.name?.slice(0, 15)}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Owner :
            </span>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <span className="title" style={{ fontWeight: "bold" }}>
                  Name:
                </span>
                <span className="detail">{videoDetail?.owner?.name}</span>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Profile Image:
            </span>

            <Avatar
              size={48}
              src={
                videoDetail.owner?.profilePic
                  ? videoDetail.owner?.profilePic
                  : null
              }
              icon={!videoDetail.owner?.profilePic && <UserOutlined />}
            />
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              IsFeatured :
            </span>
            <span className="detail">
              {videoDetail?.isFeatured ? "true" : "false"}
            </span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Likes :
            </span>
            <span className="detail">{videoDetail?.likes?.length}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Comments :
            </span>
            <span className="detail">{videoDetail?.comments?.length}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              CreatedAt :
            </span>
            <span className="detail">{videoDetail?.createdAt}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              UpdatedAt :
            </span>
            <span className="detail">{videoDetail?.updatedAt}</span>
          </div>
        </div>
      </Modal>

      <Modal
        title={selectedVideo.name}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {selectedVideo && (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                paddingTop: "56.25%",
              }}
            >
              <ReactPlayer
                url={selectedVideo.url}
                controls
                width="100%"
                height="100%"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <h2>{selectedVideo.name}</h2>
              <p>Video description or other information</p>
              <div style={{ display: "flex", alignItems: "center" }}></div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Feedbacks;
