import React, { useEffect, useState } from "react";
import { Card } from "antd";
import List from "../../components/dashboard/List";
import Pie from "../../components/dashboard/Pie";
import Chart from "../../components/dashboard/Chart";
import { getDashboardData } from "../../services/dashboard";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { Spin } from "antd";
import LineGraph from "../../components/dashboard/LineGraph";
import LineGraphLeft from "../../components/dashboard/LineGraphLeft";
import LineChartComponentUsers from "../../components/LineChartComponentUsers";

const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalInfluencers, setTotalInfluencers] = useState(0);
  const [Latestusers, setLatestUsers] = useState([]);
  const [new_user_vs_date_lineGraph, set_new_user_vs_date_lineGraph] = useState(
    []
  );

  const [reportedUsers, setreportedUsers] = useState([]);
  const [left_user_vs_date_lineGraph, set_left_user_vs_date_lineGraph] =
    useState([]);

  const [allUsers, setAllUsers] = useState([]);
  const [allInfluencers, setAllInfluencers] = useState([]);

  const navigation = useNavigate();

  const goToUsers = () => {
    // navigation("/members");
  };

  const goToVideos = () => {
    // navigation("/members");
  };

  const fetchDashboardData = () => {
    getDashboardData()
      .then((resp) => resp.data)
      .then((data) => {
        setTotalUsers(data?.totalUsers);
        setTotalInfluencers(data?.totalInfluencers);
        setreportedUsers(data?.reportedVideosOwners);

        setAllUsers(data?.allUsers);
        setAllInfluencers(data?.allInfluencers);
        set_new_user_vs_date_lineGraph(data?.new_user_vs_date_lineGraph);
        set_left_user_vs_date_lineGraph(data?.left_user_vs_date_lineGraph);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <div className="site-layout-background">
      <div className="leftSide" style={{ minHeight: 360, paddingLeft: "2%" }}>
        {/* <h2>Users </h2> */}
        <div
          className="inputFlexss"
        >
          <Card
            onClick={() => goToUsers()}
            title="USERS"
            bordered={false}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
            }}
          >
            {totalUsers ? (
              <h1 style={{ fontSize: 65, margin: 0 }}>{totalUsers}</h1>
            ) : (
              <Spin />
            )}
          </Card>
          <Card
            onClick={() => goToVideos()}
            title="INFLUENCERS"
            bordered={false}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
            }}
          >
            {totalInfluencers ? (
              <h1 style={{ fontSize: 65, margin: 0 }}>{totalInfluencers}</h1>
            ) : (
              <Spin />
            )}
          </Card>
        </div>

        <div
          style={{ marginBottom: 20, background: "white" }}
          className="chartjs"
        >
          <div className="responsivetable">
            <LineChartComponentUsers
              newUserData={new_user_vs_date_lineGraph}
              leftUserData={left_user_vs_date_lineGraph}
              title={"New Users vs Left Users Over Time"}
            />
          </div>
        </div>

        <div className="responsivetable">
          <div style={{ marginBottom: 20 }} className="chartjs">
            {allUsers?.length && (
              <Chart allUsers={allUsers} allInfluencers={allInfluencers} />
            )}
          </div>
        </div>
      </div>
      <div className="responsivetable">
        <div className="rightSide">
          <Pie totalUsers={totalUsers} totalInfluencers={totalInfluencers} />
          <List reportedUsers={reportedUsers} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
