import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  message,
  Avatar,
  Pagination,
  Select,
  Button,
  Input,
} from "antd";
import { Switch } from "antd";
import { notifyError, notifySuccess, notifyWarn } from "../../components/Toast";
import "./style.scss";
import {
  PlayCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  PlaySquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import swal from "sweetalert";

import ReactPlayer from "react-player";
import { deleteVideo, getAllVideos, updateVideo } from "../../services/videos";
import { getAllReportedVideos } from "../../services/reported_videos";
import { ThumbnailPreviewer } from "../../components/ThumbnailPreviewer";
import formatDate from "../../services/formatDate";

const { Search } = Input;
const { Column } = Table;

const ReportedVideos = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [videoDetail, setVideoDetail] = useState({});
  const [videos, setVideos] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [selectedFeature, setSelectedFeature] = useState(null);
  const [IsPrivate, setIsPrivate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default to 10 rows per page
  const [searchedText, setSearchedText] = useState("");

  const [filterObject, setfilterObject] = useState({
    isFeatured: "",
    isPrivate: "",
    name: "",
  });

  const [reload, setReload] = useState(false);

  useEffect(() => {
    fetchVideosdData(pageNumber, filterObject , rowsPerPage);
  }, [pageNumber, reload , rowsPerPage]);

  const onChangePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleDeleteVideo = () => {
    deleteVideo(deleteId)
      .then((resp) => {
        if (resp.type === "success") {
          getAllReportedVideos(pageNumber, filterObject);
          setIsDeleteModalOpen(false);
          notifySuccess(
            "Video has been successfully deleted.",
            resp.message,
            "success"
          );
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const fetchVideosdData = (pgnumber, obj , rowsPerPage) => {
    setIsLoading(true);

    getAllReportedVideos(pgnumber, rowsPerPage, { ...obj})
      .then((result) => {
        if (result.type === "success") {
          setVideos(result?.data);
          setIsLoading(false);
        } else {
          setVideos([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error("Failed to fetch videos");
        console.error(error);
        setIsLoading(false);
        setVideos([]);
      });
  };

  const handlePlayVideo = (record) => {
    setSelectedVideo(record.video);
    setModalVisible(true);
  };

  const handleFeatureChange = (id, value) => {
    const body = { isDeleted: !value };
    updateVideo(id, body)
      .then((resp) => {
        if (resp.type === "success") {
          notifySuccess("Video Featured Update", resp.message);
          fetchVideosdData(pageNumber, filterObject);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Reporter User",
      dataIndex: "thumbnailUrl",
      key: "thumbnailUrl",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Avatar
            style={{ cursor: "pointer" }}
            onClick={() => setPreviewUrl(record?.user?.profilePic)}
            size={60}
            src={record?.user?.profilePic ? record?.user?.profilePic : null}
            icon={!record?.user?.profilePic && <PlaySquareOutlined />}
          />
          <span>{record?.user?.name}</span>
        </div>
      ),
    },
    {
      title: "Reported Video",
      dataIndex: "name",
      key: "name",

      render: (text, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Avatar
            style={{ cursor: "pointer" }}
            onClick={() => setPreviewUrl(record?.video?.thumbnailUrl)}
            size={60}
            src={
              record?.video?.thumbnailUrl ? record?.video?.thumbnailUrl : null
            }
            icon={!record?.video?.thumbnailUrl && <PlaySquareOutlined />}
          />
          <span>{record?.video?.name.slice(-8)}</span>
        </div>
      ),
    },

    {
      title: "Report Date",
      dataIndex: "name",
      key: "name",

      render: (text, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <span> {formatDate(record?.createdAt)}</span>
        </div>
      ),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record) => <span>{record?.video?.category?.name}</span>,
    },
    {
      title: "isRemoved",
      dataIndex: "isFeatured",
      key: "isFeatured",
      render: (text, record) => (
        <Switch
          checked={record?.video?.isDeleted}
          onChange={() =>
            handleFeatureChange(record?.video?._id, record?.video?.isDeleted)
          }
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setVideoDetail(record);
              setIsDetailsModalOpen(true);
            }}
          >
            <EyeOutlined />
          </span>
          <span
            style={{ marginLeft: 16, cursor: "pointer" }}
            onClick={() => handlePlayVideo(record)}
          >
            <PlayCircleOutlined />
          </span>

          <span
            style={{ marginLeft: 16, cursor: "pointer" }}
            onClick={() => {
              setIsDeleteModalOpen(true);
              setdeleteId(record.video._id);
            }}
          >
            <DeleteOutlined />
          </span>
        </div>
      ),
    },
  ];

  const scrollConfig = {
    y: "60vh", // Set the maximum height in pixels or other CSS units
  };

  const handleApplyFilter = () => {
    if (!selectedFeature && !IsPrivate) {
      notifyWarn("select a value first");
      return;
    }
    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      isPrivate: IsPrivate,
    }));

    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      isFeatured: selectedFeature,
    }));

    setReload(!reload);
  };

  const handleSearch = (value, _e, info) => {
    if (!value) {
      notifyWarn("Enter v value first");
      return;
    }

    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      name: value,
    }));

    setReload(!reload);
  };

  return (
    <div className="userContainer">
      <div className="tableInfo">
        <span style={{ color: "white" }} className="spanstyle">
          {" "}
          Reported Videos
        </span>

        {previewUrl && (
          <ThumbnailPreviewer
            previewUrl={previewUrl}
            setPreviewUrl={setPreviewUrl}
          />
        )}

        {/* <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Select
            placeholder="isFeatured"
            value={selectedFeature}
            style={{
              width: 150,
            }}
            onChange={(value) => setSelectedFeature(value)}
            options={[
              {
                text: "Featured",
                value: "Featured",
              },
              {
                text: "Not Featured",
                value: "Not Featured",
              },
            ]}
          />

          <Select
            placeholder="isPrivate"
            value={IsPrivate}
            style={{
              width: 150,
            }}
            onChange={(value) => setIsPrivate(value)}
            options={[
              {
                text: "Private",
                value: "Private",
              },
              {
                text: "Public",
                value: "Public",
              },
            ]}
          />
          {selectedFeature || IsPrivate ? (
            <Button
              type="default"
              onClick={() => {
                setSelectedFeature(null);
                setIsPrivate(null);
                setfilterObject((prevFilterObject) => ({
                  ...prevFilterObject,
                  isPrivate: "",
                  isFeatured: "",
                }));
                setReload(!reload);
              }}
            >
              Reset
            </Button>
          ) : null}
          <Button type="primary" onClick={handleApplyFilter}>
            Apply Filter
          </Button>
        </div>

        <Search
          placeholder="input search text"
          allowClear
          enterButton="Search"
          size="small"
          style={{
            width: 304,
          }}
          onSearch={handleSearch}
          onChange={(e) => {
            if (!e.target.value) {
              setfilterObject((prevFilterObject) => ({
                ...prevFilterObject,
                name: "",
              }));
              setSearchedText("");
              setReload(!reload);
            }
          }}
        /> */}
      </div>

<div className="responsivetable">
<div className="tableData">
        {videos && (
          <Table
            dataSource={videos}
            columns={columns}
            loading={isLoading ? true : false}
            className="custom-table"
            scroll={{x : "100%"}}
            showSorterTooltip={{
              target: "sorter-icon",
            }}
            pagination={{ pageSize: rowsPerPage }}
          />
        )}
      </div>

      <div className="paginationComp">
        {/* Pagination Component */}
        <Pagination
          defaultCurrent={1}
          total={totalUsers}
          onChange={onChangePagination}
          pageSize={rowsPerPage}
        />
        

        {/* Rows per page Select */}
        <Select
          placeholder="Rows per page"
          value={rowsPerPage}
          style={{ width: 150, marginLeft: '16px' }} // Margin for spacing
          onChange={(value) => {
            setRowsPerPage(value);
          }}
          options={[
            { label: "5 / page", value: 5 },
            { label: "10 / page", value: 10 },
            { label: "20 / page", value: 20 },
            { label: "50 / page", value: 50 },
          ]}
        />
        {/* Your button in the bottom right corner */}
      </div>
</div>

      <Modal
        title="Delete Alert!"
        open={isDeleteModalOpen}
        onOk={handleDeleteVideo}
        okText="Confirm"
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setdeleteId(null);
        }}
      >
        <p>Are you sure to permanent delete</p>
      </Modal>

      <Modal
        title="Reported Video Details!"
        open={isDetailsModalOpen}
        onOk={() => {
          setIsDetailsModalOpen(false);
          setVideoDetail({});
        }}
        onCancel={() => {
          setIsDetailsModalOpen(false);
          setVideoDetail({});
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span className="title" style={{ fontWeight: "bold" }}>
              video Thumbnail:
            </span>
            <Avatar
              size={200}
              src={
                videoDetail?.video?.thumbnailUrl
                  ? videoDetail?.video?.thumbnailUrl
                  : null
              }
              icon={!videoDetail?.video?.thumbnailUrl && <PlaySquareOutlined />}
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Name :
            </span>
            <span className="detail">
              {videoDetail?.video?.name?.slice(0, 15)}
            </span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Reporter
            </span>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <span className="title" style={{ fontWeight: "bold" }}>
                  Name:
                </span>
                <span className="detail">{videoDetail?.user?.name}</span>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Reporter Profile Image:
            </span>

            <Avatar
              size={48}
              src={
                videoDetail.user?.profilePic
                  ? videoDetail.user?.profilePic
                  : null
              }
              icon={!videoDetail.user?.profilePic && <UserOutlined />}
            />
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              IsRemoved :
            </span>
            <span className="detail">
              {videoDetail?.isDeleted ? "true" : "false"}
            </span>
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Report Date :
            </span>
            <span className="detail">
              {" "}
              {formatDate(videoDetail?.createdAt)}
            </span>
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Report Reason :
            </span>
            <span className="detail">{videoDetail?.report?.reason}</span>
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Report Detail:
            </span>
            <span className="detail">{videoDetail?.report?.detail}</span>
          </div>
        </div>
      </Modal>

      <Modal
        title={selectedVideo.name}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {selectedVideo && (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                paddingTop: "56.25%",
              }}
            >
              <ReactPlayer
                url={selectedVideo.url}
                controls
                width="100%"
                height="100%"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <h2>{selectedVideo.name}</h2>
              <p>Video description or other information</p>
              <div style={{ display: "flex", alignItems: "center" }}></div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ReportedVideos;
