import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  message,
  Avatar,
  Pagination,
  Select,
  Tag,
  Button,
  Input,
} from "antd";

import { Switch } from "antd";
import {  notifySuccess, notifyWarn } from "../../components/Toast";
import "./playListVideoStyle.scss";
import {
  PlayCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
  PlaySquareOutlined,
  UserOutlined,
} from "@ant-design/icons";
import formatDate from "../../services/formatDate";

import ReactPlayer from "react-player";
import { deleteVideo, getAllVideos, updateVideo, fetchVideoGraphData } from "../../services/videos";

const { Search } = Input;
const { Column } = Table;

const PlayListVideosTable = ({ videosList }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [videoDetail, setVideoDetail] = useState({});
  const [videos, setVideos] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

console.log("videosList>>>>>>>>>>>>>>>>>>>>>>>>>>>>" , videosList);


  const [rowsPerPage, setRowsPerPage] = useState(10); // Default to 10 rows per page
  const [reload, setReload] = useState(false);
  const onChangePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };





  // useEffect(() => {
  //   setVideos(videosList);
  // }, [videosList]);

  const handleDeleteVideo = () => {
    deleteVideo(deleteId)
      .then((resp) => {
        if (resp.type === "success") {
          setIsDeleteModalOpen(false);
          notifySuccess(
            "Video has been successfully deleted.",
            resp.message,
            "success"
          );
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const tagColors = [
    "magenta", "red", "volcano", "orange", "gold",
    "lime", "green", "cyan", "blue", "geekblue", "purple"
  ];

  const getRandomColor = () => {
    return tagColors[Math.floor(Math.random() * tagColors.length)];
  };


  const handlePlayVideo = (record) => {
    setSelectedVideo(record);
    setModalVisible(true);
  };

  const handleFeatureChange = (id, value) => {
    const body = { isFeatured: !value };
    updateVideo(id, body)
      .then((resp) => {
        if (resp.type === "success") {
          notifySuccess("Video Featured Update", resp.message);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: <span style={{ fontSize: 12 }}>ThumNail</span>,
      dataIndex: "thumbnailUrl",
      key: "thumbnailUrl",
      render: (text, record) => (
        <Avatar
          style={{ cursor: "pointer" }}
          onClick={() => {
            setdeleteId(record._id);
            setPreviewUrl(record.thumbnailUrl);
          }}
          size={45}
          src={record.thumbnailUrl ? record.thumbnailUrl : null}
          icon={!record.thumbnailUrl && <PlaySquareOutlined />}
        />
      ),
    },


    // {
    //   title: <span style={{fontSize : 10}}>Category</span>,
    //   dataIndex: "category",
    //   key: "category",
    //   render: (text, record) => <span>{record.category?.name}</span>,
    //   sorter: (a, b) => a.category?.name.localeCompare(b.category?.name),
    // },
      
    {
      title: <span style={{ fontSize: 10 }}>CreatedAt</span>,
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text, record) => <span style={{fontSize: 9}}> {formatDate(record?.createdAt)}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },

    {
      title: <span style={{fontSize : 10}}>Tags</span>,
      dataIndex: "tags",
      key: "tags",

      render: (tags) => {

        console.warn("tags" , tags);
        
  
        return <div style={{ display: 'flex', flexWrap: 'wrap', gap: "4px" }}>
           { tags?.length && tags?.map((tag) => (
            <Tag style={{fontSize : 8}} color={getRandomColor()} key={tag}>
              #{tag ? tag.slice(0, 9) : ""}
            </Tag>
          ))}
        </div>
      }
    },

    {
      title: <span style={{ fontSize: 10 }}>IsFeatured</span>,
      dataIndex: "isFeatured",
      key: "isFeatured",
      render: (text, record) => (
        <Switch
          checked={record.isFeatured}
          onChange={() => handleFeatureChange(record._id, record.isFeatured)}
          size="small"
        />
      ),
    },
    {
      title: <span style={{ fontSize: 10 }}>Action</span>,
      key: "action",
      render: (text, record) => (
        <div>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setVideoDetail(record);
              setIsDetailsModalOpen(true);
            }}
          >
            <EyeOutlined />
          </span>
          <span
            style={{ marginLeft: 16, cursor: "pointer" }}
            onClick={() => handlePlayVideo(record)}
          >
            <PlayCircleOutlined />
          </span>

          <span
            style={{ marginLeft: 16, cursor: "pointer" }}
            onClick={() => {
              setIsDeleteModalOpen(true);
              setdeleteId(record._id);
            }}
          >
            <DeleteOutlined />
          </span>
        </div>
      ),
    },
  ];

  const scrollConfig = {
    y: "20vh", // Set the maximum height in pixels or other CSS units
  };

  return (
    <div className="userContainerpl">
      <div className="tableDatapl" style={{ border: "none !important", borderRadius: '0px !important' }} >
        {videosList && videosList.length && (
          <Table
            dataSource={videosList}
            columns={columns}
            className="custom-table"
            loading={isLoading ? true : false}
            scroll={{x : "100%"}}
            pagination={{ pageSize: rowsPerPage }}
          />
        )}
      </div>

      <div className="paginationComp">
        {/* Pagination Component */}
        <Pagination
          defaultCurrent={1}
          total={totalUsers}
          onChange={onChangePagination}
          pageSize={rowsPerPage}
        />

        {/* Rows per page Select */}
        <Select
          placeholder="Rows per page"
          value={rowsPerPage}
          style={{ width: 150, marginLeft: '16px' }} // Margin for spacing
          onChange={(value) => {
            setRowsPerPage(value);
          }}
          options={[
            { label: "5 / page", value: 5 },
            { label: "10 / page", value: 10 },
            { label: "20 / page", value: 20 },
            { label: "50 / page", value: 50 },
          ]}
        />
        {/* Your button in the bottom right corner */}
      </div>

      <Modal
        title="Delete Alert!"
        open={isDeleteModalOpen}
        onOk={handleDeleteVideo}
        okText="Confirm"
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setdeleteId(null);
        }}
      >
        <p>Are you sure to Delete...</p>
      </Modal>

      <Modal
        title="Video Details!"
        open={isDetailsModalOpen}
        onOk={() => {
          setIsDetailsModalOpen(false);
          setVideoDetail({});
        }}
        onCancel={() => {
          setIsDetailsModalOpen(false);
          setVideoDetail({});
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span className="title" style={{ fontWeight: "bold" }}>
              video Thumbnail:
            </span>

            <Avatar
              size={200}
              src={videoDetail?.thumbnailUrl ? videoDetail?.thumbnailUrl : null}
              icon={!videoDetail?.thumbnailUrl && <PlaySquareOutlined />}
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Name :
            </span>
            <span className="detail">{videoDetail?.name?.slice(0, 15)}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Owner :
            </span>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <span className="title" style={{ fontWeight: "bold" }}>
                  Name:
                </span>
                <span className="detail">{videoDetail?.owner?.name}</span>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Profile Image:
            </span>

            <Avatar
              size={48}
              src={
                videoDetail.owner?.profilePic
                  ? videoDetail.owner?.profilePic
                  : null
              }
              icon={!videoDetail.owner?.profilePic && <UserOutlined />}
            />
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              IsFeatured :
            </span>
            <span className="detail">
              {videoDetail?.isFeatured ? "true" : "false"}
            </span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Likes :
            </span>
            <span className="detail">{videoDetail?.likes?.length}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              Comments :
            </span>
            <span className="detail">{videoDetail?.comments?.length}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              CreatedAt :
            </span>
            <span className="detail">{videoDetail?.createdAt}</span>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <span className="title" style={{ fontWeight: "bold" }}>
              UpdatedAt :
            </span>
            <span className="detail">{videoDetail?.updatedAt}</span>
          </div>
        </div>
      </Modal>

      <Modal
        title={selectedVideo?.name}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {selectedVideo && (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "relative",
                paddingTop: "56.25%",
              }}
            >
              <ReactPlayer
                url={selectedVideo.url}
                controls
                width="100%"
                height="100%"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <h2>{selectedVideo?.name}</h2>
              <p>Video description or other information</p>
              <div style={{ display: "flex", alignItems: "center" }}></div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PlayListVideosTable;