import React, { useContext, useState } from "react";
import { FaSignOutAlt, FaBars, FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Auth from "../../services/auth-service";
import SideBar from "../Sidebar";
import "./style.css";
import { notifySuccess } from "../../components/Toast"; // Import toast notification functions
import { Modal, Button, Switch, Select } from "antd";
import fitnContext from "../../context/fitnContext";



const { Option } = Select;
const Header = () => {
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);

  const {showMenu, setShowMenu} = useContext(fitnContext)

  const navigate = useNavigate();

  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        notifySuccess("You have been logged out successfully");

        Auth.logout()
          .then(() => {
            console.log("logout success");
          })
          .catch((error) => {
            console.error("logout error", error);
          });

        navigate("/");
      }
    });
  };

  return (
    <div>
      <div className="header">
        <FaBars
          className="menuIcon"
          onClick={() => {

            console.warn("cleckeddddddddddddd");
            
            setShowMenu(!showMenu)
          }}

        />
        <FaSignOutAlt onClick={logout} style={{ cursor: "pointer" }} />
      </div>
      {/* Sidebar toggled based on state */}
      {showMenu && (
        <div className="mobile-sidebar">
          <div className="closeIconContainer">
            <FaTimes
              className="closeIcon"
              onClick={() => setShowMenu(false)}
            />
          </div>
          <SideBar />
        </div>
      )}
    </div>
  );
};

export default Header;