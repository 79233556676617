import {
  AreaChartOutlined,
  UserOutlined,
  UnorderedListOutlined,
  VideoCameraOutlined,
  PlayCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import Swal from "sweetalert2";
import { MdMessage } from "react-icons/md";

import {
  notifySuccess,
  notifyInfo,
  notifyConfirm,
} from "../../components/Toast"; // Make sure to import the relevant notification functions
import fitnContext from "../../context/fitnContext";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("DASHBOARD", "sub1", <AreaChartOutlined />),
  getItem("USERS", "sub2", <UserOutlined />),
  getItem("CATEGORIES", "sub3", <UnorderedListOutlined />),
  getItem("VIDEOS", "sub4", <VideoCameraOutlined />),
  // getItem("PLAYLISTS", "sub5", <PlayCircleOutlined />),

  getItem("CHATS", "sub6", <MdMessage />),
  getItem("REPORTED VIDEOS", "sub7", <VideoCameraOutlined />),
  getItem("FEED BACKS", "sub8", <VideoCameraOutlined />),
  getItem("INFLUENCERS", "sub9", <UserOutlined />),
];

const App = () => {
  const navigate = useNavigate();
  const {showMenu, setShowMenu} = useContext(fitnContext)

  const logout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        notifySuccess("You have been logged out successfully");
        navigate("/");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        notifyInfo("Logout canceled");
      }
    });
  };

  const handleMenuItemClick = (key) => {
    switch (key) {
      case "sub1":
        navigate("/dashboard");
        break;
      case "sub2":
        navigate("/members");
        break;
      case "sub3":
        navigate("/category");
        break;
      case "sub4":
        navigate("/reels");
        break;
      case "sub5":
        navigate("/playlist");
        break;

      case "sub6":
        navigate("/ChatScreen");
        break;

      case "sub7":
        navigate("/reported_videos");
        break;
      case "sub8":
        navigate("/feedbacks");
        break;

        case "sub9":
          navigate("/influencer");
          break;

      default:
        break;
    }
    setShowMenu(false);
  };

  return (
    <div className="sidebarMain" style={{ maxHeight: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "auto",
          background: "rgba(39, 253, 150, 0.8)",
        }}
        className="sidebarLogo"
      >
        <h2 style={{ color: "black", fontSize: 34 }}>F I T N</h2>
      </div>
      <Menu
        style={{
          width: "auto",
          background: "rgba(39, 253, 150, 0.8)",
          color: "black",
          fontSize: 13,
          fontWeight: 1000,
        }}
        defaultSelectedKeys={["1"]}
        mode="inline"
      >
        {items.map((item) => (
          <Menu.Item
            key={item.key}
            icon={item.icon}
            onClick={() => handleMenuItemClick(item.key)}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default App;
