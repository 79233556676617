import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Switch,
  Select,
  Space,
  Avatar,
  Form,
  Input,
  Radio,
  Pagination,
  Button,
  Tooltip,
} from "antd";
import {
  notifyError,
  notifySuccess,
  notifyWarn,
  notifyInfo,
} from "../../components/Toast";
import { deleteUser, getAllUser, updateUser } from "../../services/users";
import "./style.scss";

import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { MdFilterAlt } from "react-icons/md";
import PlayListsComp from "../Playlist";
import { DatePicker } from "antd";
import { ThumbnailPreviewer } from "../../components/ThumbnailPreviewer";
import formatDate from "../../services/formatDate";

const { Search } = Input;
const { Column } = Table;

function InfluencerTable() {
  const [users, setUsers] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [totalUsers, setTotalUsers] = useState(0);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [deleteId, setdeleteId] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default to 10 rows per page
  const [userIdPlayListShow, setuserIdPlayListShow] = useState(null);

  const [openPlayListModel, setOpenPlayListModel] = useState(false);

  const [userName, setUserName] = useState("");
  const [FeatureValue, setFeatureValue] = useState(null);
  const [RoleValue, setRoleValue] = useState(null);

  const [selectedRole, setSelectedRole] = useState('influencer');
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [date, setIsdate] = useState(null);
  const [searchedText, setSearchedText] = useState("");

  const [filterObject, setfilterObject] = useState({
    isFeatured: "",
    selectedRole: "influencer",
    date: "",
    startDate: null, endDate: null,
    name: "",
  });

  const [pageNumber, setPageNumber] = useState(1);

  const [reload, setReload] = useState(false);

  const onChangeFeature = (e) => {
    setFeatureValue(e.target.value);
  };

  const handleChangeName = (e) => {
    setUserName(e.target.value);
  };

  useEffect(() => {
    fetchUsersdData(pageNumber, filterObject);
  }, [pageNumber, reload, rowsPerPage]);

  const fetchUsersdData = (pgnmbr, obj) => {
    setIsLoading(true);
    obj.role = 'influencer'
    getAllUser(pgnmbr, obj)
      .then((resp) => {
        if (resp.type === "success") {
          setUsers(resp?.data?.users);
          setTotalUsers(resp?.data?.totalUsers);
          setIsLoading(false);
        } else {
          setIsLoading(false);

          setUsers([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setUsers([]);
      });
  };

  const handleDelete = () => {
    setConfirmLoading(true);
    deleteUser(deleteId)
      .then((resp) => {
        if (resp.type === "success") {
          fetchUsersdData(pageNumber, filterObject);
          setIsDeleteModalOpen(false);
          notifySuccess("User has been successfully deleted ");
          setConfirmLoading(false);
        }
      })

      .catch((err) => {
        // notifyError("", resp.message, "Error");
        console.log(err);
        setConfirmLoading(false);
      });
  };

  const handleEdit = () => {
    setConfirmLoading(true);

    let body = {
      isFeatured: FeatureValue,
      name: userName,
    };

    // if (RoleValue === "user") {
    //   body = {
    //     permissions: "null",
    //     role: RoleValue,
    //     isFeatured: FeatureValue,
    //     name: userName,
    //   };
    // }

    updateUser(userDetail._id, body)
      .then((resp) => {
        if (resp.type === "success") {
          notifySuccess(" Permission " + resp.message, "success");
          fetchUsersdData(pageNumber, filterObject);
          setConfirmLoading(false);
        }
      })

      .catch((err) => {
        console.log(err);
        setConfirmLoading(false);
      });

    setUserName(null);
    setRoleValue(null);
    setFeatureValue(null);
    setIsEditModalOpen(false);
    setUserDetail({});
  };

  const handlePermissionChange = (id, value) => {
    let body = {};

    if (value === "approved") {
      body = { permissions: value, role: "influencer" };
    } else {
      body = { permissions: value, role: "user" };
    }

    updateUser(id, body)
      .then((resp) => {
        if (resp.type === "success") {
          notifySuccess(" Permission " + resp.message, "success");
          fetchUsersdData(pageNumber, filterObject);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleFeatureChange = (id, value) => {
    const body = { isFeatured: !value };
    updateUser(id, body)
      .then((resp) => {
        if (resp.type === "success") {
          notifySuccess("Featured " + resp.message);
          fetchUsersdData(pageNumber, filterObject);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteImage = () => {
    let body = {
      profilePic:
        "https://fitn-bucket.s3.eu-west-2.amazonaws.com/1718204937278.jpeg",
    };

    updateUser(deleteId, body)
      .then((resp) => {
        if (resp.type === "success") {
          notifySuccess("Image Replaced");

          fetchUsersdData(pageNumber, filterObject);
          setdeleteId(null);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleStatusChange = (id, value) => {
    const body = { inActive: !value };
    updateUser(id, body)
      .then((resp) => {
        if (resp.type === "success") {
          notifySuccess("Status " + resp.message);
          fetchUsersdData(pageNumber, filterObject);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpenPlayListClick = (id) => {
    setuserIdPlayListShow(id);
    setOpenPlayListModel(true);
  };

  const onChangePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const columns = [
    {
      title: "ProfilePic",
      dataIndex: "profilePic",
      key: "profilePic",
      render: (text, record) => (
        <Avatar
          style={{ cursor: "pointer" }}
          onClick={() => {
            setdeleteId(record._id);
            setPreviewUrl(record.profilePic);
          }}
          size={48}
          src={record.profilePic ? record.profilePic : null}
          icon={!record.profilePic && <UserOutlined />}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",

      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Featured",
      dataIndex: "isFeatured",
      key: "isFeatured",

      render: (text, record) => (
        <Switch
          checked={record.isFeatured}
          onChange={() => handleFeatureChange(record._id, record.isFeatured)}
        />
      ),
    },

    {
      title: "Status",
      dataIndex: "inActive",
      key: "inActive",

      render: (text, record) => (
        <Tooltip title={record.inActive ? "Inactive" : "Active"}>
          <Switch
            checked={record.inActive ? false : true}
            onChange={() => handleStatusChange(record._id, record?.inActive)}
          />
        </Tooltip>
      ),
    },

    {
      title: "PlayLists",
      dataIndex: "_id",
      key: "_id",

      render: (text, record) => (
        <Button onClick={() => handleOpenPlayListClick(record._id)}>
          Open List
        </Button>
      ),
    },

    {
      title: "Permission",
      key: "permissions",
      dataIndex: "permissions",
      render: (text, record) => (
        <Select
          defaultValue={record.permissions}
          style={{
            width: 150,
          }}
          onChange={(selectedValue) =>
            handlePermissionChange(record._id, selectedValue)
          }
          options={[
            {
              value: "null",
              label: "default",
            },
            {
              value: "requested",
              label: "requested",
            },
            {
              value: "approved",
              label: "approved",
            },
            {
              value: "rejected",
              label: "rejected",
            },
          ]}
        />
      ),
    },

    {
      title: "Created",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (text, record) => <span> {formatDate(record?.createdAt)}</span>,
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setUserDetail(record);
              setIsDetailsModalOpen(true);
            }}
          >
            <EyeOutlined />
          </span>
          <span
            style={{ marginLeft: 10, cursor: "pointer" }}
            onClick={() => {
              setUserDetail(record);
              setIsEditModalOpen(true);

              setUserName(record?.name);
              setRoleValue(record?.role);
              setFeatureValue(record?.isFeatured);
            }}
          >
            <EditOutlined />
          </span>
          <span
            style={{ marginLeft: 10, cursor: "pointer" }}
            onClick={() => {
              setIsDeleteModalOpen(true);
              setdeleteId(record._id);
            }}
          >
            <DeleteOutlined />
          </span>
        </>
      ),
    },
  ];

  const scrollConfig = {
    y: "60vh", // Set the maximum height in pixels or other CSS units
  };

  const handleApplyFilter = () => {
    if (!selectedFeature && !selectedRole && (!dateRange[0] || !dateRange[1])) {
      notifyWarn("select a value first");
      return;
    }

    const startDate = dateRange[0] ? dateRange[0].toISOString() : null;
    const endDate = dateRange[1] ? dateRange[1].toISOString() : null;

    setfilterObject((prevFilterObject) => {
      const updatedFilter = {
        ...prevFilterObject,
        selectedRole: selectedRole,
        isFeatured: selectedFeature,
        startDate: startDate, endDate: endDate,
      };
      console.log("Filter Object:", updatedFilter);
      return updatedFilter;
    });

    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      selectedRole: selectedRole,
    }));

    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      isFeatured: selectedFeature,
    }));

    setReload(!reload);
  };

  const handleSearch = (value, _e, info) => {
    if (!value) {
      notifyWarn("Enter v value first");
      return;
    }

    setfilterObject((prevFilterObject) => ({
      ...prevFilterObject,
      name: value,
    }));

    setReload(!reload);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates); // Set the selected date range
  };

  return (
    <div className="userContainer">
      <div className="tableInfo">
        <span style={{ color: "white" }} className="spanstyle">
          INFLUENCERS
        </span>

        {previewUrl && (
          <ThumbnailPreviewer
            previewUrl={previewUrl}
            setPreviewUrl={setPreviewUrl}
            handleDeleteImage={handleDeleteImage}
          />
        )}

        <div
        className="influencerstablebutton"
        >
          {/* <Select
            placeholder="Role"
            value={selectedRole}
            style={{
              width: 150,
            }}
            onChange={(value) => setSelectedRole(value)}
            options={[
              {
                text: "User",
                value: "user",
              },
              {
                text: "Influencer",
                value: "influencer",
              },
            ]}
          /> */}
          <Select
            placeholder="isFeatured"
            value={selectedFeature}
            style={{
              width: 150,
            }}
            onChange={(value) => setSelectedFeature(value)}
            options={[
              {
                text: "Featured",
                value: "Featured",
              },
              {
                text: "Not Featured",
                value: "Not Featured",
              },
            ]}
          />

          <DatePicker.RangePicker
            style={{ width: 150 }}
            onChange={handleDateChange}
            format="YYYY-MM-DD" // Format for display
          />


          {selectedRole || selectedFeature || date ? (
            <Button
              type="default"
              onClick={() => {
                setSelectedRole(null);
                setSelectedFeature(null);
                setfilterObject((prevFilterObject) => ({
                  ...prevFilterObject,
                  selectedRole: "",
                }));

                setfilterObject((prevFilterObject) => ({
                  ...prevFilterObject,
                  isFeatured: "",
                }));

                setReload(!reload);
              }}
            >
              Reset
            </Button>
          ) : null}
          <Button type="primary" onClick={handleApplyFilter}>
            Apply Filter
          </Button>
        </div>

        <Search
          placeholder="input search text"
          allowClear
          enterButton="Search"
          size="small"
          style={{
            width: 304,
          }}
          onSearch={handleSearch}
          onChange={(e) => {
            if (!e.target.value) {
              setfilterObject((prevFilterObject) => ({
                ...prevFilterObject,
                name: "",
              }));
              setReload(!reload);
            }
          }}
        />
      </div>

      <div className="responsivetable">
        <div className="tableData">
          {users && (
            <Table
              dataSource={users}
              columns={columns}
              className="custom-table"
              loading={isLoading ? true : false}
              scroll={{ x: "100%" }}
              pagination={{ pageSize: rowsPerPage }}
            />
          )}
        </div>

        <div className="paginationComp">
          <Pagination
            defaultCurrent={1}
            total={totalUsers}
            onChange={onChangePagination}
            pageSize={rowsPerPage}
          />

          <Select
            placeholder="Rows per page"
            value={rowsPerPage}
            style={{ width: 150, marginLeft: '16px' }} // Margin for spacing
            onChange={(value) => {
              setRowsPerPage(value);
            }}
            options={[
              { label: "5 / page", value: 5 },
              { label: "10 / page", value: 10 },
              { label: "20 / page", value: 20 },
              { label: "50 / page", value: 50 },
            ]}
          />
        </div>
      </div>

      <div className="models">
        <Modal
          confirmLoading={confirmLoading}
          title="Delete Alert!"
          open={isDeleteModalOpen}
          onOk={handleDelete}
          okText="Confirm"
          onCancel={() => {
            setIsDeleteModalOpen(false);
            setdeleteId(null);
          }}
        >
          <p>Are you sure to Delete...</p>
        </Modal>

        <Modal
          title="Edit User!"
          open={isEditModalOpen}
          confirmLoading={confirmLoading}
          onOk={handleEdit}
          okText="Update"
          onCancel={() => {
            setIsEditModalOpen(false);
            setUserDetail({});
            setUserName(null);
            setRoleValue(null);
            setFeatureValue(null);
          }}
        >
          <div className="formContainer">
            <div className="inputContainer">
              <div className="spanData">
                <span>Name : </span>
              </div>
              <div className="inputdata">
                <Input
                  autocomplete="off"
                  value={userName}
                  onChange={handleChangeName}
                />
              </div>
            </div>

            <div className="inputContainer">
              <div className="spanData">
                <span>IsFeatured : </span>
              </div>
              <div className="inputdata">
                <Radio.Group onChange={onChangeFeature} value={FeatureValue}>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </div>
            </div>

            {/* <div className="inputContainer">
              <div className="spanData">
                <span>Role : </span>
              </div>
              <div className="inputdata">
                <Radio.Group onChange={onChangeRole} value={RoleValue}>
                  <Radio value="user">User</Radio>
                  <Radio value="influencer">Influencer</Radio>
                </Radio.Group>
              </div>
            </div> */}
          </div>
        </Modal>

        <Modal
          title="User Details!"
          open={isDetailsModalOpen}
          onOk={() => {
            setIsDetailsModalOpen(false);
            setUserDetail({});
          }}
          onCancel={() => {
            setIsDetailsModalOpen(false);
            setUserDetail({});
          }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span className="title" style={{ fontWeight: "bold" }}>
                Profile Pic :
              </span>

              <Avatar
                size={200}
                src={userDetail.profilePic ? userDetail.profilePic : null}
                icon={!userDetail.profilePic && <UserOutlined />}
              />
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <span className="title" style={{ fontWeight: "bold" }}>
                Name :
              </span>
              <span className="detail">{userDetail?.name}</span>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <span className="title" style={{ fontWeight: "bold" }}>
                Email :
              </span>
              <span className="detail">{userDetail?.email}</span>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <span className="title" style={{ fontWeight: "bold" }}>
                Role :
              </span>
              <span className="detail">{userDetail?.role}</span>
            </div>{" "}
            <div style={{ display: "flex", gap: "10px" }}>
              <span className="title" style={{ fontWeight: "bold" }}>
                IsFeatured :
              </span>
              <span className="detail">
                {userDetail?.isFeatured ? "true" : "false"}
              </span>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <span className="title" style={{ fontWeight: "bold" }}>
                Followers :
              </span>
              <span className="detail">{userDetail?.followers?.length}</span>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <span className="title" style={{ fontWeight: "bold" }}>
                Following :
              </span>
              <span className="detail">{userDetail?.following?.length}</span>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <span className="title" style={{ fontWeight: "bold" }}>
                CreatedAt :
              </span>
              <span className="detail">
                {userDetail?.createdAt ? "true" : "false"}
              </span>
            </div>
          </div>
        </Modal>

        <div
          id="myModal"
          className="modal"
          style={{ display: openPlayListModel ? "block" : "none" }}
        >
          <div class="modal-content">
            <span
              onClick={() => {
                setuserIdPlayListShow(null);
                setOpenPlayListModel(false);
              }}
              class="close"
            >
              &times;
            </span>
            <PlayListsComp userId={userIdPlayListShow} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default InfluencerTable;
